html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  background-color: #f0f0f0;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  font-kerning: normal;
  font-smooth: always;
  font-feature-settings: "kern", "liga", "clig", "calt";
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}
