@font-face {
  font-family: "Mono";
  src: url("./fonts/ABCMonumentGroteskSemi-Mono-Regular.woff") format("woff"),
    url("./fonts/ABCMonumentGroteskSemi-Mono-Regular.woff2") format("woff2");
}

.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #f8f8f8;
  font: 10px/15px "Mono";
  color: #111;

  @media screen and (max-width: 480px) {
    font-size: 2.2vw;
    line-height: 1.5;
  }

  .link {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #777;
    }
  }
}
